import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';


export default function Confirm(props) {

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

  const handleClose = () => {
    props.close()
  };

  return (      
      <Dialog
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.open === 'pass' ? "Are you sure you want to PASS this test case?" : "Are you sure you want to FAIL this test case?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => props.confirm()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}