import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";

import TestTable from "../../components/TestTable";

import { url, testPhase } from "../../config/config";
import getTestCases from "../../utilities/getTestCases";
import useStyles from "./styles";

export default function Home(props) {
  var classes = useStyles();
  var [data, setData] = useState(null);

  useEffect(() => {
    if (!data) {
      getData();
    }

    const script = document.createElement("script");
    script.src =
      "https://esc-partners.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=82c685b2";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  const getData = async (testCycle) => {
    console.log("getting data");
    try {
      if (!testCycle) {
        testCycle = testPhase;
      }
      // debugger;
      var result = await getTestCases(testCycle);
      // var result = await axios.get(`${url()}/testcases/?testPhase=Integration Test`);
      console.log(result);
      if (result.success) {
        setData(result.data);
        // console.log(data);
      }
    } catch (e) {
      debugger;
      console.error(e);
    }
  };

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid item xs={11}>
        <TestTable data={data} getData={getData} />
      </Grid>
    </Grid>
  );
}
