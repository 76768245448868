
export const url = () => {
    if (window.location.hostname === "localhost") {
        return "http://localhost:5000";
     } else {
        return ""
     }
}

export const testPhase = "Integration Test";



