import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { callMsGraph } from "./graph";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import Login from "./components/Login";
import Home from "./screens/home";
import TestCase from "./screens/testCase";

import "./App.css";

function App() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <HashRouter>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/testcase" component={TestCase} />
          </Switch>
        </HashRouter>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
