import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
container:{
    paddingTop: 20
},
newsText:{
    height: '100%'
}
}));
