import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Grid, Button } from "@material-ui/core";


export default function Login () {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item>
                <Button 
                variant="contained" 
                style={{position: "absolute", top: "20vh",left:"40vw"}}
                onClick={() => handleLogin("popup")}>Login to Testing Tool</Button>
            </Grid>
        </Grid>


        // <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign In">
        //     <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>Sign in using Popup</Dropdown.Item>
        //     <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item>
        // </DropdownButton>
    )





}