import React from "react";
import { Grid, Tabs, Tab } from "@material-ui/core";
import TestForm from "../../components/TestForm";
import axios from "axios";
import { url } from "../../config/config";

class TestCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      tab: 0
    };
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      //   debugger;
      var data = this.props.history.location.state.data;
      // this.setState({ data: data });
      this.getTestCase(data.key)
      this.getComments(data.key);
    } else {
      this.props.history.push("/");
    }
  }

  getTestCase = async (key) => {
    console.log("getting test case");
    try {
      // debugger;
      var result = await axios.get(`${url()}/testcase/${key}`);
      if (result.data.success) {
        this.setState({ data: result.data.data });
        console.log("Comments retrieved");
        // console.log(data);
      }
    } catch (e) {
      debugger;
      console.error(e);
    }
  }

  getComments = async (key) => {
    console.log("getting comments");
    try {
      // debugger;
      var result = await axios.get(`${url()}/comments/${key}`);
      if (result.data.success) {
        this.setState({ comments: result.data.data });
        console.log("Comments retrieved");
        // console.log(data);
      }
    } catch (e) {
      debugger;
      console.error(e);
    }
  };

  render() {
    return (
      <Grid container style={{paddingTop: 20}} justifyContent="center">
        <Grid item xs={11}>
            <TestForm data={this.state.data} comments={this.state.comments}/>
        </Grid>
      </Grid>
    );
  }
}

export default TestCase;
