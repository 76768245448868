import React, { useState, useEffect } from "react";

import jira2md from '../utilities/jiraFormatter';

import './jira.css';



export default function TestSteps(props) {

    // useEffect(()=>{
    //     var tables = document.getElementsByTagName('table');
    //     if (tables.length === 1){
    //         tables[0].classList.add('table');
    //     };

    //     var ths = document.getElementsByTagName('th');
    //     if (ths.length > 0){
    //         for (var i = 0; i < ths.length; i++)
    //         ths[i].classList.add('th');
    //     }
    //     var trs = document.getElementsByTagName('tr');
    //     if (trs.length > 0){
    //         for (var i = 0; i < trs.length; i++)
    //         trs[i].classList.add('tr');
    //     }
    //     var tds = document.getElementsByTagName('td');
    //     if (tds.length > 0){
    //         for (var i = 0; i < tds.length; i++)
    //         tds[i].classList.add('td');
    //     }
    // })
    
    if (props.data){
        // var tdata = "|| Step # || Step Description || Expected Result || Defect || Comments || \n"
        return (
            // <div dangerouslySetInnerHTML={{__html: jira2md.jira_to_html(props.data)}} />
            <div dangerouslySetInnerHTML={{__html: props.data}} />

        )
    } else {
        return <React.Fragment />
    }

}