import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import axios from 'axios';
import { url } from '../config/config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function Comments(props) {
  const classes = useStyles();
  var [comments, setComments] = React.useState(props.data.comments);
  var [showButtons, setShowButtons] = React.useState('none');
  var [text, setText] = React.useState('');


  const handleText = (val) => {
    setText(val);
    if (showButtons === 'none') {
      setShowButtons('block')
    }
  }

  const handleCancel = () => {
    setText('');
    setShowButtons('none');
  }

  const handleSave = async () => {
    try {
      var data = {
        key: props.data.data.key,
        comment: text,
      };
      if (props.data.data.tester) {
        data.user = props.data.data.tester;
      }
      var result = await axios.post(`${url()}/comment`, data);
      if (result.data.success) {
        console.log("Comment saved succesfully");
        var c = comments;
        comments.push(result.data.data);
        setComments(c);
        setText('');
        setShowButtons('none');
        // console.log(data);
      }
    } catch (e) {
      debugger;
      console.error(e);
    }

  }

  return (
    <Grid container direction="column" spacing={3}>
      <Paper style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid item>
          <List style={{ padding: 10 }}>
            {comments
              .slice(0)
              .reverse()
              .map((c) => {
                return (
                  <React.Fragment>
                    {/* <ListItem style={{ padding: 10 }}>{c.body}</ListItem> */}
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            variant="button"
                            color="textPrimary"
                          >
                            {moment(c.created).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            component="div"
                            variant="body1"
                            color="textPrimary"
                          >
                            {c.body}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    <Divider />
                  </React.Fragment>
                );
              })}
          </List>
        </Grid>
        <Grid item style={{ padding: 20 }}>
          <TextField
            multiline
            fullWidth
            placeholder="Add a comment... "
            variant="outlined"
            value={text}
            onChange={(e) => handleText(e.target.value)}
          />
        </Grid>
        <Grid item style={{paddingLeft:0, display: showButtons}}>
          <Grid container direction="row" spacing={4} justifyContent="flex-start" >
            <Grid item xs={2}>
              <Button
                variant="outlined"
                style={{ backgroundColor: "#0052cc", color: "#FFFFFF" }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={1} style={{display:'flex', justifyContent: 'flex-start'}}>
              <Button
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
