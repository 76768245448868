import React, { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  Paper,
  Button,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
// import Search from '@material-ui/icons/Search';
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import FilterList from "@material-ui/icons/FilterList";
import MaterialTable from "material-table";
import Create from "./Create";
import { useHistory } from "react-router-dom";
import { url } from "../config/config";
import axios from "axios";
import getTestCases from "../utilities/getTestCases";

const tableIcons = {
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
};

export default function TestTable(props) {
  var classes = useStyles();
  const history = useHistory();
  var [data, setData] = useState(props.data);
  var [open, setOpen] = useState(false);
  var [testPhase, setTestPhase] = useState("Integration Test");
  var [showSpinner, setShowSpinner] = useState("none");

  // React.useEffect(() => {
  //   if (props.data !== data) {
  //     setData(props.data);
  //   }
  // })

  const handleRowClick = (row) => {
    var tc = props.data.filter((d) => d.key === row.id)[0];
    history.push({
      pathname: "/testcase",
      state: { data: tc },
      // search: `?id=${row.id}`
    });
    // history.push("/testcase")
  };

  const getRows = () => {
    if (props.data) {
      return props.data.map((row) => {
        return {
          id: row.key,
          name: row.summary,
          status: row.status,
          tester: row.tester,
        };
      });
    } else {
      return [];
    }
  };

  const handleTestPhaseChange = (e) => {
    setTestPhase(e.target.value);
    updateTestPhase(e.target.value);
  };

  const updateTestPhase = async (value) => {
    try {
      setShowSpinner("block");
      await props.getData(value);
      setShowSpinner("none");
    } catch (e) {
      debugger;
      console.error(e);
      setShowSpinner("none");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        style={{ position: "absolute", top: "5%", left: "20%", zIndex: 1000 }}
        onClick={() => setOpen(true)}
      >
        Create
      </Button>
      <FormControl
        style={{
          position: "absolute",
          top: "4%",
          left: "40%",
          zIndex: 1000,
          width: 140,
        }}
      >
        <InputLabel id="demo-simple-select-label">Test Phase</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={testPhase}
          onChange={handleTestPhaseChange}
        >
          <MenuItem value={"Functional Test"}>Functional Test</MenuItem>
          <MenuItem value={"Integration Test"}>Integration Test</MenuItem>
          <MenuItem value={"Parallel Test"}>Parallel</MenuItem>
          <MenuItem value={"UAT"}>UAT</MenuItem>
        </Select>
      </FormControl>
      <Paper className={classes.paper}>
        <MaterialTable
          columns={[
            { title: "ID", field: "id" },
            { title: "Name", field: "name" },
            { title: "Status", field: "status" },
            { title: "Tester", field: "tester" },
          ]}
          data={getRows()}
          title="Test Cases"
          options={{
            paging: false,
            filtering: true,
            rowStyle: {
              backgroundColor: (rowData) =>
                rowData.status === "Failed" ? "red" : "blue",
            },
          }}
          icons={tableIcons}
          onRowClick={(e, row) => handleRowClick(row)}
        />
      </Paper>
      <Create open={open} handleClose={() => setOpen(false)} />
      <CircularProgress
        color="secondary"
        style={{
          display: showSpinner,
          width: 60,
          height: 60,
          position: "fixed",
          top: "30%",
          left: "50%",
        }}
      />
    </div>
  );
}

const useStyles = makeStyles({
  paper: {
    width: "100%",
  },
});
