import React, { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import axios from "axios";
// import moment from "moment";
// import Search from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { url } from "../config/config";
import Confirm from "./Confirm";
import Comments from "./Comments";
import FeedBack from "./FeedBack";
import TestSteps from "./TestSteps";

export default function TestForm(props) {
  var classes = useStyles();
  var history = useHistory();
  var [data, setData] = useState(null);
  var [actual, setActual] = useState("");
  var [actualError, setActualError] = useState(null);
  var [showConfirm, setShow] = useState(false);
  var [tab, setTab] = useState(0);
  var [files, setFiles] = useState([]);
  var [alertMessage, setAlertMessage] = useState(null);
  var [alertSeverity, setAlertSeverity] = useState(null);
  var [showTestBtns, setShowTestBtns] = useState("flex");
  var [tester, setTester] = useState(null);
  var [attImg, setAttImg] = useState("");

  useEffect(() => {
    if (!data && props.data) {
      setData(props.data);
      setTester(props.data.tester);
      if (props.data.status === "Pass" || props.data.status === "Failed") {
        setShowTestBtns("none");
      }
    }
  });

  const handleActual = (e) => {
    setActual(e.target.value);
    if (actualError) {
      setActualError(null);
    }
  };

  const handleButton = (btn) => {
    if (btn === "cancel") {
      history.goBack();
    } else if (!actual && btn === "fail") {
      console.error("No actual result provided");
      setActualError("Please enter some details of the failure");
    } else if (files.length === 0 && btn === "fail") {
      console.error("No attachments");
      setAlertSeverity("error");
      setAlertMessage(
        "Please attach screenshots and/or files to support the failure"
      );
    } else {
      setShow(btn);
    }
  };

  const handleConfirmClose = () => {
    setShow(false);
  };

  const handleConfirmProceed = async () => {
    console.log(`confirmed to proceed with ${showConfirm}`);
    setShow(false);
    try {
      var result = await axios.post(
        `${url()}/transition?key=${props.data.key}&action=${showConfirm}`
      );
      if (result.data.success) {
        var cData = {
          key: props.data.key,
          comment: `${props.data.tester}: ${actual}`,
        };
        var result = await axios.post(`${url()}/comment`, cData);
      }
      if (result.data.success) {
        if (showConfirm === "pass") {
          data.status = "Pass";
        } else {
          data.status = "Failed";
        }
        setData(data);
        setShowTestBtns("none");
        setAlertSeverity("success");
        setAlertMessage("Test case updated successfully");
        // console.log(data);
      } else {
        setAlertSeverity("error");
        setAlertMessage(`Error updating status: ${result.data.error}`);
      }
    } catch (e) {
      console.error(e);
      setAlertSeverity("error");
      setAlertMessage(`Error updating status: ${e.message}`);
    }
  };

  const handleFiles = async (e) => {
    var Files = e.target.files;
    console.log(`Uploading ${Files.length} files`);
    var formData = new FormData();

    for (var file of Files) {
      formData.append("File", file);
    }

    try {
      var result = await axios.post(`${url()}/attachments`, formData, {
        headers: { "Content-Type": "multipart/form-data", key: props.data.key },
      });
      if (result.data.success) {
        var d = data;
        if (d.attachments) {
          for (var r of result.data.data) {
            d.attachments.push(r[0]);
          }
          setData(d);
        } else {
          d.attachments = result.data.data.map((r) => {
            return r[0];
          });
          setData(d);
        }
        setAlertSeverity("success");
        setAlertMessage(result.data.message);
        var f = files;
        for (var file of Files) {
          f.push(file);
        }
        setFiles(f);
      }
    } catch (e) {
      console.error(`Error posting attachments to server: ${e}`);
      setAlertSeverity("error");
      setAlertMessage(`Error uploading attachment: ${e.message}`);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage(null);
  };

  const renderForm = () => {
    // debugger;
    return (
      // <Grid item md={9} xs={12}>
      <Paper style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container direction="column" spacing={5}>
          <Grid item xs={12}>
            <TextField
              label="Summary"
              variant="standard"
              value={data ? data.summary : "-"}
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Pre-conditions"
              variant="standard"
              value={data ? data.preConditions : "-"}
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Test Data"
              variant="standard"
              value={data ? data.testData : "-"}
              style={{ width: "90%" }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              multiline
              label="Test Steps"
              variant="standard"
              value={data ? data.testSteps : "-"}
              style={{ width: "90%" }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography
              variant="caption"
              component="div"
              align="left"
              style={{ marginLeft: "5%", marginBottom: 10 }}
            >
              Test Steps
            </Typography>
            <TestSteps data={data ? data.testSteps : null} />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Expected Result"
              variant="standard"
              value={data ? data.expectedResult : "-"}
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Actual Result"
              variant="standard"
              value={actual ? actual : ""}
              style={{ width: "90%" }}
              fullWidth
              onChange={(e) => handleActual(e)}
              helperText={actualError ? actualError : ""}
              error={actualError ? true : false}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justify: "flex-start",
              paddingLeft: "7%",
            }}
          >
            <div className={classes.root}>
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFiles}
              />
              <label htmlFor="contained-button-file">
                <Button startIcon={<AttachFileIcon />} component="span">
                  Attach
                </Button>
              </label>
            </div>
          </Grid>

          <Grid item xs={12} style={{ display: showTestBtns }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              style={{ paddingLeft: "5%" }}
              spacing={3}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "#12824C", color: "#FFFFFF" }}
                  onClick={() => handleButton("pass")}
                >
                  Pass
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "#c63148", color: "#FFFFFF" }}
                  onClick={() => handleButton("fail")}
                >
                  Fail
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleButton("cancel")}>Cancel</Button>
              </Grid>
              <Confirm
                open={showConfirm}
                close={handleConfirmClose}
                confirm={() => handleConfirmProceed()}
              />
            </Grid>
          </Grid>
        </Grid>
        <FeedBack
          message={alertMessage}
          severity={alertSeverity}
          setOpen={handleCloseAlert}
        />
      </Paper>
      // </Grid>
    );
  };

  const handleSaveTester = async () => {
    if (tester !== data.tester) {
      console.log(tester);
      try {
        var update = {
          key: data.key,
          field: "customfield_10084",
          value: tester,
        };

        var result = await axios.post(`${url()}/update`, update);
        if (result.data.success) {
          console.log("Tester updated ");
          data.tester = tester;
          setData(data);
          setAlertSeverity("success");
          setAlertMessage("Tester updated successfully");
        }
      } catch (e) {}
    }
  };

  const getAttachment = async (id, mimeType) => {
    try {
      var result = await axios.get(`${url()}/attachment/${id}`);
      // var result = await axios.get("https://placekitten.com/200/140", {responsetype: "arrayBuffer"});
      if (result.data.success) {
        debugger;
        var doc = Buffer.from(result.data.data, "binary").toString("base64");
        setAttImg(`data:${mimeType};base64,${doc}`);
      }
    } catch (e) {
      console.error(`Error getting attachment from Jira: ${e}`);
      setAlertSeverity("error");
      setAlertMessage(`Error retrieving attachment: ${e.message}`);
    }
  };

  const renderAttachments = () => {
    if (data) {
      if (Array.isArray(data.attachments)) {
        debugger;
        return (
          <Grid item xs={6}>
            <List>
              {data.attachments ? (
                data.attachments.map((a) => {
                  return (
                    <React.Fragment key={a.filename}>
                      <ListItem
                        key={a.id}
                        onClick={() => getAttachment(a.id, a.mimetype)}
                      >
                        <ListItemText>{a.filename}</ListItemText>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })
              ) : (
                <React.Fragment />
              )}
            </List>
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={attImg}
              onClose={() => setAttImg("")}
              aria-labelledby="max-width-dialog-title"
            >
              
              <DialogContent>
                <img id="att" src={attImg} />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setAttImg("")}
                  color="secondary"
                  variant="contained"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        );
      }
    } else {
      return <React.Fragment />;
    }
  };

  const renderTabContent = () => {
    if (tab === 0) {
      return renderForm();
    } else if (tab === 1) {
      return <Comments data={props} />;
    } else if (tab === 2) {
      return renderAttachments();
    }
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item md={3} xs={12}>
        <Paper style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <TextField
                label="ID"
                variant="standard"
                value={data ? data.key : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Status"
                variant="standard"
                value={data ? data.status : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Tester"
                variant="standard"
                value={tester ? tester : ""}
                onChange={(e) => setTester(e.target.value)}
                onBlur={() => handleSaveTester()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Test Cycle"
                variant="standard"
                value={data ? data.testCycle : ""}
              />
            </Grid>
          </Grid>
        </Paper>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 20, width: "50%" }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Grid>

      <Grid item md={9} xs={12}>
        <Tabs value={tab} onChange={(e, newVal) => setTab(newVal)}>
          <Tab label="Test Case"></Tab>
          <Tab label="Comments"></Tab>
          <Tab label="Attachments"></Tab>
        </Tabs>
        {renderTabContent()}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  paper: {
    width: "100%",
  },
});
