import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Link,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { url } from "../config/config";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Create(props) {
  const classes = useStyles();
  // const fileRef = useRef();
  var [success, setSuccess] = React.useState({status: false, message: ""});
  var [loading, setLoading] = React.useState(false);

  // const openPicker = () => {
  //   console.log("Pick me!")
  //   fileRef.current.click()
  // }

  const handleFiles = async (e) => {
    var files = e.target.files;
    console.log(`Uploading ${files.length} files`);
    // debugger;
    var formData = new FormData();

    for (var file of files) {
      formData.append("File", file);
    }
    // debugger;
    try {
      setTimeout(() => {setLoading(true)}, 500);

      var result = await axios.post(`${url()}/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      if (result.data.success) {
        setSuccess({status: true, message: result.data.message});
      }
    } catch (e) {
      setLoading(false);
      console.error(`Error posting attachments to server: ${e}`);
      setSuccess({status: false, message: e.message});
    }
  };

  const closeDialog = () => {
    props.handleClose();
    setSuccess(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const renderSuccess = () => {

    if (loading) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={5}
          direction="column"
          style={{ padding: 20, height: "100%" }}
        >
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      )
    }
    if (success.status) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={5}
          direction="column"
          style={{ padding: 20, height: "100%" }}
        >
          <Grid item>
            <CheckCircleOutlineIcon
              style={{ color: "green", fontSize: "4.1875rem" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">{success.message}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => closeDialog()}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={5}
          direction="column"
          style={{ padding: 20, height: "100%" }}
        >
          <Grid item>
            <Typography variant="h5">
              Upload a test case template file to create a new test case
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.root}>
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFiles}
                // ref={fileRef}
              />
              <label htmlFor="contained-button-file">
                <Button
                  component="span"
                  variant="contained"
                  // onClick={openPicker}
                >
                  Choose File
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Don't have a template? Get one{" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://escpartners.sharepoint.com/:x:/r/sites/MorenoValleyCCS/_layouts/15/Doc.aspx?sourcedoc=%7BCF776598-FE79-4AA6-91E3-CEFCAEC3C318%7D&file=Test%20Script%20Template.xlsx&action=default&mobileredirect=true"
              >
                here
              </Link>
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Upload
          </Typography>
        </Toolbar>
      </AppBar>
      {renderSuccess()}
    </Dialog>
  );
}
