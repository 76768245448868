
import axios from 'axios';
import { url } from "../config/config";


export default async function getTestCases(testPhase) {


    console.log("getting data");
    try {
      // debugger;
      var result = await axios.get(`${url()}/testcases/?testPhase=${testPhase}`);
      console.log(result);
      if (result.data.success) {
        // setData(result.data.data);
        return {success: true, data: result.data.data}
        // console.log(data);
      }
    } catch (e) {
      debugger;
      console.error(e);
      return {success: false, error: e.message}
    }

}